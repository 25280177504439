
$animation_base_delay: 100ms;
//$animation_base_delay: 1s;

@for $i from 1 through 10 {
  .animate__animated.animate__delay-#{$i} {
    animation-delay: $animation_base_delay * $i;
  }
}

.animate__animated{
  animation-fill-mode: backwards !important;
}
