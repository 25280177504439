
.quill {
  background-color: white;
}

.ql-editor {
  min-height: 150px !important;
}
.ql-editor.internal-note-body{
  min-height: auto !important;
  padding: 0;
}
