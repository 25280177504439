
.dropdown-color-picker{
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.color-component{
  box-shadow: 0 0 3px rgba(0, 0, 0, .1);
  border-radius: .25rem;
}
