@keyframes uploading {
  0%{
    color: #5e6e82;
  }
  50%{
    color: #2c7be5;

  }
  100%{
    color: #5e6e82;
  }
}

.is-uploading{
  animation: uploading 3s infinite;
}
