@import "components/risk-indicator";
@import "components/dropdown-color-picker";
@import "components/internal-notes";
@import "components/toastify";
@import "components/quill";
@import "components/switch";
@import "utils/custom-animate-delay";
@import "utils/brand-cover";
@import "utils/uploader-annimation";
@import "utils/codemirror-styles";
@import "utils/report-helpers";

#iframe{
  @media screen and (min-width: 800px) {
    margin-right: 100px;
    margin-left: 100px;
  }
}

.avatar .rounded-circle {
  //background-color: white;
}

// No way to do sentence case on CSS
.cursor-pointer {
  cursor: pointer;
}

.spacing-logo-helper {
  @media screen and (min-width: 1200px) {
    height: 70px;
  }
}


.scroll-shadow {
  position: relative;
  z-index: 1;
  overflow: auto;
  max-height: 200px;
  background: #FFF no-repeat;
  background-image: -webkit-radial-gradient(50% 0, farthest-side, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)), -webkit-radial-gradient(50% 100%, farthest-side, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0));
  background-image: -moz-radial-gradient(50% 0, farthest-side, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)), -moz-radial-gradient(50% 100%, farthest-side, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0));
  background-image: radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)), radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0));
  background-position: 0 0, 0 100%;
  background-size: 100% 10px;
}

.scroll-shadow:before,
.scroll-shadow:after {
  content: "";
  position: relative;
  z-index: -1;
  display: block;
  height: 30px;
  margin: 0 0 -30px;
  background: -webkit-linear-gradient(top, #FFF, #FFF 30%, rgba(255, 255, 255, 0));
  background: -moz-linear-gradient(top, #FFF, #FFF 30%, rgba(255, 255, 255, 0));
  background: linear-gradient(to bottom, #FFF, #FFF 30%, rgba(255, 255, 255, 0));
}

.scroll-shadow:after {
  margin: -30px 0 0;
  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0), #FFF 70%, #FFF);
  background: -moz-linear-gradient(top, rgba(255, 255, 255, 0), #FFF 70%, #FFF);
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0), #FFF 70%, #FFF);
}

body, #main {
  height: 100vh;
}

.table-prepare-is-loading {
  position: relative;

  &:after {
    content: '';
    transition: background-color 300ms;
  }
}

.table-is-loading {
  position: relative;

  &:after {
    z-index: 1;
    content: '';
    transition: background-color 300ms;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(255, 255, 255, .4);
  }
}

.table-responsive {
  min-height: 200px;
}

.table-responsive-no-min-height .table-responsive {
  min-height: auto;
}

.thumbnail-max-width {
  max-width: 250px;
}

pre {
  white-space: pre-wrap; /* css-3 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
}

$tracking-page-size: 663px;
.buy-button-sidebar {
  @media screen and (min-width: 767px) {
    min-width: 300px;
  }
  max-width: 300px;
}

.chrome-window {
  height: 100%;
  min-height: 500px;
  background-color: #D6DAE0;
  border-radius: 5px;
  position: relative;

  display: flex;
  flex-direction: column;
  transition: opacity 300ms;

  .chrome-tab {
    display: flex;
    flex-direction: column;

    .tab-navigation {
      $height: 52px;
      height: $height;
      background-color: white;
      width: 100%;
      display: flex;
      align-items: center;

      .tab-action-url {
        border: solid 1px rgba(0, 0, 0, .4);
        flex-grow: 1 !important;
        margin-right: 1rem !important;
        border-radius: 1rem !important;
        padding-left: 1rem;
      }

      .tab-control {
        //outline: solid 1px red;
        height: $height;
        width: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .tab-title {
      padding-left: 10px;
      padding-right: 10px;
      max-width: 300px;
      margin-top: 5px;
      margin-left: 10px;
      margin-right: 20px;
      display: flex;
      align-items: center;
      background-color: white;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      height: 36px;

      .fa-times {
        color: rgba(0, 0, 0, .3)
      }

      .chrome-favicon {
        min-width: 32px;
        height: 32px;

      }

    }

    iframe {
      width: 100%;
      flex-grow: 1 !important;
    }

  }
}


.search-box-icon-loading {
  left: 14px;
  top: 8px;
}

.preview-img {
  max-width: 150px !important;
  width: 100%;
  margin: auto;
  aspect-ratio: 1/1;
  object-fit: contain;
}

.invisible-thumbnail {
  height: 65px !important;
  width: 65px;
  display: block;
  position: relative;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 5px;
}

.animate-rotate {
  transition: transform 300ms;
}

form input:focus {
  box-shadow: none !important;
}

.ck-editor__editable_inline {
  min-height: 200px;
  max-height: 200px;
}

textarea {
  resize: none !important;
}


.react-datepicker-popper {
  z-index: 3000;
}

// this style is designed to remove the color when the button is clicked.
.btn-secondary-flat:focus,
.btn-secondary-flat:active {
  background-color: transparent !important;
  color: #5e6e82 !important;
  box-shadow: none !important;
}

.btn-group .btn.active {
  z-index: 0 !important;
}

.btn-group-responsive {
  .btn.active {
    z-index: 0 !important;
  }

  @media screen and (max-width: 576px) {
    flex-direction: column;

    .btn:first-child {
      //box-sizing: border-box;
      width: 100%;
      margin-left: 0 !important;
      border-bottom-right-radius: 0 !important;
      border-top-left-radius: .2rem !important;
      border-top-right-radius: .2rem !important;
    }
    .btn {
      margin-left: 0 !important;
      margin-top: -1px !important;
      border-radius: 0 !important;
      //border-bottom-right-radius: 0;
      //border-bottom-left-radius: 0;
      //border-top-right-radius: 0;
      //border-top-left-radius: 0;
    }
    .btn:last-child {
      border-top-right-radius: 0 !important;
      border-bottom-left-radius: .2rem !important;
      border-bottom-right-radius: .2rem !important;
    }
  }
}


.custom-react-select__control:focus {
  border: 1px solid #96bdf2 !important;
}

.custom-react-select__control {
  border: 1px solid #d8e2ef !important;
}

//
//.form-select-sm{
//  .custom-react-select__single-value{
//    padding: 0;
//    //outline: solid 1px red;
//  }
//  .custom-react-select__value-container{
//    background-color: red;
//  }
//  .custom-react-select__control,
//
//  .custom-react-select__value-container,
//  .custom-react-select__input-container{
//    padding: 0 !important;
//    margin: 0 !important;
//    outline: solid 1px green;
//  }
//  .custom-react-select__indicator,
//  .custom-react-select__indicators,
//  .custom-react-select__value-container,
//  .custom-react-select__control {
//    height: 29px !important;
//    min-height: 29px !important;
//    line-height: 1 !important;
//    &, *{font-size: .875rem !important};
//
//  }
//}


.custom-react-select__control {
  z-index: 999 !important;
}

.custom-react-select__menu {
  z-index: 1000 !important;
}

.custom-react-select__indicator-separator {
  width: 1px !important;
}

.select-multiple {
  .custom-react-select__indicator,
  .custom-react-select__indicators,
  .custom-react-select__value-container,
  .custom-react-select__control {
    min-height: auto !important;
  }
}

// integration with date-range-calendar on dropdown
.rdrDateDisplayWrapper {
  background-color: white;
}

@media screen and (max-width: 767px) {
  .rdrCalendarWrapper, .rdrDateRangeWrapper {
    width: 100%;
  }

  .rdrMonth {
    width: 100% !important;
  }

  .rdrDefinedRangesWrapper {
    display: none;
  }
  .rdrMonthsHorizontal {
    flex-direction: column;
  }
}

//
//
//.product-item{
//  display: grid;
//  grid-template-columns: 85px 4fr 1fr 1fr;
//}
//


.order-item {
  border-bottom: solid 1px rgba(0, 0, 0, .05);
}


.order-item:last-child {
  border-bottom: none;
}

@media screen and (max-width: 925px) {
  .rdrDefinedRangesWrapper {
    display: none;
  }
}

@media screen and (min-width: 767px) {
  .standalone-brand-selector {
    max-width: 350px;
  }
}

.card-table,
.card-table thead,
.card-table thead tr {
  border-top-left-radius: .375rem;
  border-top-right-radius: .375rem;
}

.card-table thead tr th:first-child {
  border-top-left-radius: .375rem;
}


.card-table thead tr th:last-child {
  border-top-right-radius: .375rem;
}

.btn:focus {
  box-shadow: none !important;
}

$size: 2rem;
.rounded-button {
  border-radius: 100% !important;
  width: $size !important;
  height: $size !important;
  padding: 0 !important;;
  display: flex !important;;
  align-content: center !important;;
  align-items: center !important;;
  justify-content: center !important;
}

.grid-item-field {
  width: 100%;
}

@media screen and (min-width: 576px) {
  .grid-item-field {
    max-width: 150px !important;
    width: auto !important;
  }
}

.navbar-backdrop {
  cursor: pointer;
  background-color: rgba(0, 0, 0, .4);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100vh;
  width: 100vw;
  position: fixed;
  z-index: 1019;
}

@media only screen and (max-width: 1200px) {
  .navbar-glass, .navbar-vertical {
    z-index: 1020 !important;
  }
}

.auto-break-row {
  max-width: 400px !important;
  min-width: 300px !important;
  word-wrap: break-word !important;
  word-break: break-word !important;
  white-space: normal !important;
}


.dropdown-user-wrapper {
  .dropdown-menu-card {
    margin-right: .5rem;
  }
}

@mixin buy-button-menu() {
  &:first-child {
    border-top-left-radius: .375rem !important;
  }
  padding: 1rem;
  background-color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
  box-shadow: 0 7px 14px 0 rgba(65, 69, 88, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.07);
  position: relative;
  min-width: 0;
  word-wrap: break-word;
  background-clip: border-box;
  border: 0 solid rgba(0, 0, 0, 0.125);
  border-bottom: solid 1px rgba(0, 0, 0, .1);
  border-radius: 0.375rem;
  color: #5e6e82;
  transition: border-right 300ms;
  border-right: solid 0 #ffffff;

  &.active {
    border-right: solid 2px #2363DF;
  }


  @media screen and (max-width: 767px) {
    &:first-child {
      border-top-left-radius: .375rem !important;
      border-top-right-radius: .375rem !important;
    }
    &.active {
      border-right: none !important;
      border-bottom: solid 2px #2363DF;
    }
  }
}

.buy-button-menu {
  @include buy-button-menu;
}

.tracking-page-menu-button {
  @include buy-button-menu;
  border-radius: 0 !important;
  text-decoration: none !important;

}

.cursor-pointer {
  cursor: pointer !important;
}

$breakpoint: 992px;

.tracking-page-wrapper {
  height: auto;
  @media screen and (min-width: $breakpoint) {
    height: 75vh;
  }

  #iframe {
    @media screen and (max-width: $breakpoint) {
      aspect-ratio: 9 / 19.5;
    }
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}

.tracking-page-sidebar {
  width: auto;
  @media screen and (min-width: $breakpoint) {
    width: 300px !important;
  }
}


.rdrDayWeekend .rdrDayEndPreview,
.rdrDayEndOfMonth .rdrDayStartPreview,
.rdrDayEndPreview {
  right: 2px;
}

.rdrDayStartOfWeek .rdrDayStartPreview,
.rdrDayStartOfMonth .rdrDayStartPreview,
.rdrDayStartPreview {
  left: 2px;
}

.rdrDayInPreview,
.rdrDayEndPreview,
.rdrDayStartPreview {
  bottom: 5px;
  top: 5px;
}


.custom-react-select__control--is-disabled {
  color: var(--falcon-input-color) !important;

  .custom-react-select__single-value {
    color: var(--falcon-input-color) !important;
  }
}

.mantine-Input-wrapper .mantine-Input-disabled {
  border: solid 1px var(--falcon-input-border-color) !important;
  color: var(--falcon-input-color) !important;
  background-color: var(--falcon-200) !important;
  opacity: 1;
}

.mantine-Input-wrapper .mantine-Input-disabled,
.mantine-Input-wrapper .mantine-Input-disabled:disabled,
.custom-react-select__control--is-disabled,
.custom-react-select__control--is-disabled:disabled {
  background-color: var(--falcon-200) !important;
  opacity: 1;
}

.form-control {
  box-shadow: none !important;
}

.rdrDayInPreview,
.rdrDayEndPreview,
.rdrDayStartPreview {
  bottom: 5px;
  top: 5px;
}

.form-check-label {
  line-height: 1.5rem !important;
  display: flex;
  align-items: center;
}

.Toastify__toast-body {
  a {
    color: white;
    font-weight: bold;
  }

  a:hover {
    color: white;
  }

  color: white;
}

.form-check-label {
  line-height: 1.5rem !important;
  display: flex;
  align-items: center;
}

.custom-react-select__control--is-disabled {
  //.custom-react-select--is-disabled{
  background-color: #edf2f9 !important;
}

.sticky-sidebar, .react-datepicker-popper, #launcher {
  z-index: 999 !important;
}

.brand-item-label {
  flex-shrink: 0;
  width: 80px;
}

.brand-switch-input {
  margin-top: 12px
}

.brand-shipping-groups {
  flex-wrap: wrap;
}

@media (max-width: 765px) {
  .brand-shipping-groups .col-xl-4 {
    max-width: 50%;
  }
}

@media (max-width: 599px) {
  .brand-shipping-groups .col-12 {
    max-width: 100% !important;
  }
}

// lets see if someone notice that...
.card {
  box-shadow: none !important;
  border: solid 1px rgba(65, 69, 88, 0.1) ;
}


.pre-code {
  word-break: break-all;
  font-family: "Courier New", monospace;
  cursor: copy;
  background-color: rgba(0, 0, 0, 0.05);
  color: rgba(0, 0, 0, 0.8);
  overflow: auto;
  border-bottom-right-radius: 0.375rem;
  font-size: 12px;
}

.linkedProducts-input-field {
  td{
    //vertical-align: middle;
  }
  tbody tr:last-child:not(.error-message) td{
    border-bottom-width: 0 !important;
  }

  tbody tr:not(.error-message) td{

    border-bottom-width: 1px;
  }
}

.custom-tab-menu {
  padding: 5px !important;

  .dropdown-item {
    border-radius: 5px;
    margin-top: 5px;

    &:first-child {
      margin-top: 0;
    }
  }
}

.block-overflow {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  max-width: 100%;
  display: block;
  word-wrap: normal;
  white-space: nowrap;
}


.total-line-height {
  line-height: 19px !important;
}

.risk-insights-widget-container {
  padding: 0;
  position: relative;
}

.progress-container {
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.progress-relative-container {
  position: relative;
}

// shhh, lets see :D
.dropdown-menu-card,
.dropdown-menu{
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  .dropdown-item{
    padding: 8px 12px;
    &:hover{
      &:first-child{
        border-top-right-radius: 0.375rem;
        border-top-left-radius: 0.375rem;
      }
      &:last-child{
        border-bottom-right-radius: 0.375rem;
        border-bottom-left-radius: 0.375rem;
      }
    }
  }
}

.custom-react-select__value-container{
  max-height: 36px;
}
.custom-react-select__value-container--is-multi{
  max-height: initial;
}


.custom-react-select__indicator,
.custom-react-select__indicators,
.custom-react-select__value-container,
.custom-react-select__control {
  line-height: normal !important;
}
body .custom-react-select__indicator{
  padding: 7px;

}

body .custom-react-select__dropdown-indicator,
body .custom-react-select__indicator,
body .custom-react-select__indicators,
body .custom-react-select__control{
  line-height: normal !important;
  min-height: 34px !important;
}
.custom-react-select__menu-list{
  padding-top: 0px !important;
  padding-bottom: 0px !important;

  .custom-react-select__option{
    &:first-child{
      border-top-right-radius: 4px;
      border-top-left-radius: 4px;
    }
    &:last-child{
      border-bottom-right-radius: 4px;
      border-bottom-left-radius: 4px;
    }
  }
}

.ql-disabled {
  background-color: var(--falcon-200);
  opacity: 1
}

.ql-toolbar-disabled {
  pointer-events: none;
  background-color: var(--falcon-200);
  opacity: 1
}

@media (max-width: 991px) {
  .react-datepicker-popper {
      z-index: 99999 !important;
 }
}

.theme-wizard .nav-link.finished  {
      color: #007cff;
}

.fullscreen-page .content{
  display: flex;
  flex-direction: column;
  //padding-top: 100px;
  //height: calc(100% - 200px);
}
.kanban-modal-root{
  .card {
    box-shadow: none !important;
    border: solid 1px rgba(65, 69, 88, 0.1) !important;
  }

}

#shadow-host-companion{
  z-index: -1;
}

.status-history-scroll-container {
  max-height: 500px;
  overflow-y: auto;
}


.disabled-layer{
  z-index: 1000;
  background-color: rgba(255, 255, 255, .5);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
}




.simple-timeline-item{
  position: relative;
  padding-left: 80px;
  //
  //.bullet{
  //  &:before{
  //    position: absolute;
  //    content: '';
  //    background-color: red;
  //    width: 20px;
  //    height: 40px;
  //    left: 0;
  //  }
  //  &:after{
  //    position: absolute;
  //    content: '';
  //    background-color: blue;
  //    width: 20px;
  //    height: 40px;
  //    right: 0;
  //  }
  //}

  .bullet{
    top: 0;
    left: 20px;
    position: absolute;
  }
  &.center-bullet{
    .bullet{
      top: calc(50% - 20px);
    }
    &:not(:last-child)::before {
      top: 50%;
    }
  }

  &:not(:last-child)::before {
    content: "";
    width: 2px;
    position: absolute;
    //border-left: 2px solid var(--falcon-gray-300);
    background-color: var(--falcon-gray-300);
    height: calc(100%);
    left: 39px;
    top: 0;
  }
}
