.Toastify__toast,
.Toastify__toast-body {
  a {
    color: white;
    font-weight: bold;
    word-wrap: break-word !important;
    word-break: break-word !important;
    white-space: normal !important;

  }

  a:hover {
    color: white;
  }

  svg, svg * {
    color: white;
  }

  color: white;
}
