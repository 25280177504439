
.cm-wrap {
  height: 600px;
  max-width: 100%;
  border: none !important;
  font-size: 12px
}

.cm-small .cm-wrap {
  height: 300px;
  border: 1px solid #ccc !important;
}

.cm-scroller {
  overflow: auto;
}

.cm-focused {
  outline: none !important;
}
