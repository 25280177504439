
.ball{
  height: 20px;
  display: inline-table;
  border-radius: 100%;
  width: 20px;

  &.ball-sm{
    width: 13px;
    height: 13px;
  }
}

.rdrDefinedRangesWrapper, .rdrStaticRanges, .rdrStaticRange{
  border-top-left-radius: .375rem;
  border-bottom-left-radius: .375rem;
}
.rdrCalendarWrapper, .rdrDateRangeWrapper{
  border-top-right-radius: .375rem;
  border-bottom-right-radius: .375rem;
}
