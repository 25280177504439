
@mixin risk-indicator-theme($color) {
  &, &.badge {
    color: white !important;
    background-color: $color !important;
  }
}
.risk-indicator-bar{
  width: 35px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bg-risk-normal {
  @include risk-indicator-theme(#F76867);
}

.bg-risk-elevated {
  @include risk-indicator-theme(#E1161B);
}
