
.cover {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 120px;
  min-height: 120px;
  background-color: rgba(0, 0, 0, .1);
  display: flex;
  align-items: center;

  width: 100%;
  position: relative;

  //&.filled:before {
  //  content: 'Preview here';
  //  text-align: center;
  //  display: flex;
  //  align-items: center;
  //  justify-content: center;
  //  position: absolute;
  //  top: 10px;
  //  left: 10px;
  //  right: 10px;
  //  bottom: 10px;
  //  border: dashed 1px rgba(0, 0, 0, .5);
  //}

  .cover-logo img{
    display: table;
    margin-left: 20px;
    object-fit: cover;
    object-position: center;
    height: 80px;
    min-height: 80px;
    width: 80px;
    min-width: 80px;
    //margin-left: 3rem;
    //margin-bottom: 10px;
    //max-width: 120px;
    //height: 80px;
    //width: 80px;

  }
}
